<template>
  <v-card :loading="loading" height="100%" rounded="lg" color="background" flat>
    <v-card-title>
      {{ $t('customer.orders.defineApprovalRound') }}
    </v-card-title>
    <v-card-text>
      <v-form :disabled="loading">
        <v-row>
          <template v-if="isManualCreationMode">
            <v-col :lg="6" :md="6" :sm="12">
              <v-card flat :loading="loading" outlined rounded="lg">
                <v-card-title class="text-title font-weight-semibold mb-2">
                  {{ $t('customer.orders.defineOrderApprovalRound') }}
                </v-card-title>
                <v-card-text>
                  <validation-provider
                    rules="required"
                    :name="$t('customer.orders.orderApprovalWorkflow')"
                    v-slot="{ errors }"
                  >
                    <cz-autocomplete
                      :value="value.orderApprovalWorkflowId"
                      :items="
                        accountWorkflows.filter(
                          (item) => item.type === 'order-approval-workflow'
                        )
                      "
                      dense
                      :label="$t('customer.orders.orderApprovalWorkflow')"
                      item-text="name"
                      item-value="_id"
                      :placeholder="
                        $t('customer.orders.selectApprovalWorkflow')
                      "
                      @change="onSelectedOrderApprovalWorkflowChange"
                      :error-messages="errors"
                      :readonly="readonly"
                      clearable
                    >
                      <!-- <template #selection="{ item }">
                        <div
                          class="d-flex align-center justify-space-between"
                          style="gap: 1.5rem"
                        >
                          <span class="text-body-1">{{ item.name }}</span>
                          <cz-chip
                            color="buttonSecondary"
                            circle-edges
                            x-small
                            :text="
                              $t('customer.workflowManagementPage.stepsCount', [
                                item.steps.length
                              ])
                            "
                            style="max-width: auto"
                          />
                        </div>
                      </template> -->
                      <template #item="{ item }">
                        <div
                          class="d-flex align-center justify-space-between full-width py-4"
                          style="column-gap: 20px"
                        >
                          <div class="text-body-1 mb-1">{{ item.name }}</div>
                          <cz-chip
                            color="buttonSecondary"
                            circle-edges
                            x-small
                            :text="
                              $t('customer.workflowManagementPage.stepsCount', [
                                item.steps.length
                              ])
                            "
                            style="max-width: auto"
                          />
                        </div>
                      </template>
                    </cz-autocomplete>
                  </validation-provider>
                  <v-stepper
                    v-if="value.orderApprovalWorkflowSteps.length"
                    outlined
                    flat
                    color="info"
                  >
                    <v-stepper-header class="flex-nowrap">
                      <template
                        v-for="(
                          step, index
                        ) in value.orderApprovalWorkflowSteps"
                      >
                        <v-stepper-step
                          :key="step.accountWorkflowStepId"
                          :icon="mdiCheck"
                          :step="step.stepNumber"
                          :complete-icon="
                            step.approverUserId ? mdiCheck : mdiCancel
                          "
                          complete
                          :color="!step.approverUserId ? 'error' : 'success'"
                        >
                          {{ step.stepTitle }}
                        </v-stepper-step>
                        <v-divider
                          v-if="
                            index < value.orderApprovalWorkflowSteps.length - 1
                          "
                          :key="`${step.accountWorkflowStepId}_divider`"
                        />
                      </template>
                    </v-stepper-header>
                  </v-stepper>
                  <v-row class="overflow-x-hidden mt-4">
                    <v-col
                      :lg="6"
                      :md="12"
                      :sm="12"
                      v-for="step in value.orderApprovalWorkflowSteps"
                      :key="step._id"
                    >
                      <cz-form-field
                        :label="step.stepTitle"
                        :required="!readonly"
                      >
                        <validation-provider
                          rules="required"
                          :name="step.stepTitle"
                          v-slot="{ errors }"
                        >
                          <cz-autocomplete
                            :value="step.approverUserId"
                            @change="onOrderApproverUserChanged($event, step)"
                            :error-messages="errors"
                            outlined
                            dense
                            :items="getApproverForWorkflowStep(step)"
                            item-text="fullName"
                            item-value="_id"
                            :placeholder="
                              step.approverRole === 'customer-authorizer'
                                ? $t('customer.orders.selectOrderAuthorizer')
                                : $t('customer.orders.selectOrderPurchaser')
                            "
                            :readonly="readonly"
                            clearable
                          >
                            <template #selection="{ item }">
                              <div
                                class="d-flex align-center"
                                style="column-gap: 20px"
                              >
                                <cz-avatar
                                  class="mx-0"
                                  :size="25"
                                  color="buttonSecondary"
                                  :name="item.fullName"
                                  initials-size="text-subtitle-2 text-uppercase"
                                />
                                <span> {{ item.fullName }}</span>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <cz-icon
                                      :src="mdiFileSign"
                                      v-if="item.isDigitalArchiveSigner"
                                      v-on="on"
                                      v-bind="attrs"
                                    />
                                  </template>
                                  <span class="text-pre-wrap">{{
                                    $t(
                                      'customer.digitalArchive.isDigitalArchiveSignerHint'
                                    )
                                  }}</span>
                                </v-tooltip>
                              </div>
                            </template>
                            <template #item="{ item }">
                              <v-list-item-avatar>
                                <cz-avatar
                                  :size="25"
                                  color="buttonSecondary"
                                  :name="item.fullName"
                                  initials-size="font-weight-semibold text-subtitle-2 text-uppercase"
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.fullName }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action
                                v-if="item.isDigitalArchiveSigner"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div
                                      class="d-flex align-center"
                                      style="column-gap: 10px"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <cz-icon :src="mdiFileSign" />

                                      <span class="text-subtitle-2">{{
                                        $t(
                                          'customer.digitalArchive.isDigitalArchiveSigner'
                                        )
                                      }}</span>
                                    </div>
                                  </template>
                                  <span class="text-pre-wrap">{{
                                    $t(
                                      'customer.digitalArchive.isDigitalArchiveSignerHint'
                                    )
                                  }}</span>
                                </v-tooltip>
                              </v-list-item-action>
                            </template>
                          </cz-autocomplete>
                        </validation-provider>
                      </cz-form-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
          <v-col :lg="6" :md="6" :sm="12">
            <v-card :loading="loading" outlined rounded="lg">
              <v-card-title class="text-title font-weight-semibold mb-2">
                {{ $t('customer.orders.defineOrderSubmissionApprovalRound') }}
              </v-card-title>
              <v-card-text>
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.orderApprovalWorkflow')"
                  v-slot="{ errors }"
                >
                  <cz-autocomplete
                    :value="value.orderSubmissionWorkflowId"
                    :items="
                      accountWorkflows.filter(
                        (item) => item.type === 'order-submission-workflow'
                      )
                    "
                    dense
                    :label="$t('customer.orders.orderApprovalWorkflow')"
                    item-text="name"
                    item-value="_id"
                    :placeholder="$t('customer.orders.selectApprovalWorkflow')"
                    @change="onSelectedOrderSubmissionlWorkflowChange"
                    :error-messages="errors"
                    :readonly="readonly"
                    clearable
                  >
                    <!-- <template #selection="{ item }">
                      <div
                        class="d-flex align-center justify-space-between"
                        style="gap: 1.5rem"
                      >
                        <div class="text-body-1 mb-1">{{ item.name }}</div>
                        <cz-chip
                          color="buttonSecondary"
                          circle-edges
                          x-small
                          :text="
                            $t('customer.workflowManagementPage.stepsCount', [
                              item.steps.length
                            ])
                          "
                          style="max-width: auto"
                        />
                      </div>
                    </template> -->
                    <template #item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between full-width py-4"
                        style="column-gap: 20px"
                      >
                        <div class="text-body-1 mb-1">{{ item.name }}</div>
                        <cz-chip
                          color="buttonSecondary"
                          circle-edges
                          x-small
                          :text="
                            $t('customer.workflowManagementPage.stepsCount', [
                              item.steps.length
                            ])
                          "
                          style="max-width: auto"
                        />
                      </div>
                    </template>
                  </cz-autocomplete>
                </validation-provider>
                <v-stepper
                  outlined
                  width="100%"
                  color="info"
                  v-if="value.orderSubmissionWorkflowSteps.length"
                >
                  <v-stepper-header class="flex-nowrap">
                    <template
                      v-for="(
                        step, index
                      ) in value.orderSubmissionWorkflowSteps"
                    >
                      <v-stepper-step
                        :key="step.accountWorkflowStepId"
                        :icon="mdiCheck"
                        :step="step.stepNumber"
                        :complete-icon="
                          step.approverUserId ? mdiCheck : mdiCancel
                        "
                        complete
                        :color="!step.approverUserId ? 'error' : 'success'"
                      >
                        {{ step.stepTitle }}
                      </v-stepper-step>
                      <v-divider
                        v-if="
                          index < value.orderSubmissionWorkflowSteps.length - 1
                        "
                        :key="`${step.accountWorkflowStepId}_divider`"
                      />
                    </template>
                  </v-stepper-header>
                </v-stepper>
                <v-row class="overflow-x-hidden mt-4">
                  <v-col
                    :lg="6"
                    :md="12"
                    :sm="12"
                    v-for="step in value.orderSubmissionWorkflowSteps"
                    :key="step._id"
                  >
                    <cz-form-field
                      :label="step.stepTitle"
                      :required="!readonly"
                    >
                      <validation-provider
                        rules="required"
                        :name="step.stepTitle"
                        v-slot="{ errors }"
                      >
                        <cz-autocomplete
                          :value="step.approverUserId"
                          :error-messages="errors"
                          :items="getApproverForWorkflowStep(step)"
                          item-text="fullName"
                          item-value="_id"
                          dense
                          :placeholder="
                            step.approverRole === 'customer-authorizer'
                              ? $t('customer.orders.selectOrderAuthorizer')
                              : $t('customer.orders.selectOrderPurchaser')
                          "
                          :readonly="readonly"
                          @change="
                            onOrderSubmissionApproverUserChanged($event, step)
                          "
                          clearable
                        >
                          <template #selection="{ item }">
                            <div
                              class="d-flex align-center"
                              style="column-gap: 20px"
                            >
                              <cz-avatar
                                :size="25"
                                color="buttonSecondary"
                                :name="item.fullName"
                                initials-size="font-weight-semibold text-subtitle-2 text-uppercase"
                              />
                              <span class="text-body-1">
                                {{ item.fullName }}</span
                              >
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <cz-icon
                                    :src="mdiFileSign"
                                    v-if="item.isDigitalArchiveSigner"
                                    v-on="on"
                                    v-bind="attrs"
                                  />
                                </template>
                                <span class="text-pre-wrap">{{
                                  $t(
                                    'customer.digitalArchive.isDigitalArchiveSignerHint'
                                  )
                                }}</span>
                              </v-tooltip>
                            </div>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-avatar>
                              <cz-avatar
                                :size="25"
                                color="buttonSecondary"
                                :name="item.fullName"
                                initials-size="font-weight-semibold text-subtitle-2 text-uppercase"
                              />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fullName }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="item.isDigitalArchiveSigner"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <div
                                    class="d-flex align-center"
                                    style="column-gap: 10px"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                    <cz-icon :src="mdiFileSign" />

                                    <span class="text-subtitle-2">{{
                                      $t(
                                        'customer.digitalArchive.isDigitalArchiveSigner'
                                      )
                                    }}</span>
                                  </div>
                                </template>
                                <span class="text-pre-wrap">{{
                                  $t(
                                    'customer.digitalArchive.isDigitalArchiveSignerHint'
                                  )
                                }}</span>
                              </v-tooltip>
                            </v-list-item-action>
                          </template>
                        </cz-autocomplete>
                      </validation-provider>
                    </cz-form-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <v-card :loading="loading" outlined rounded="lg">
              <v-card-title class="text-title font-weight-semibold mb-2">
                {{ $t('customer.orders.defineAccountManagementRound') }}
              </v-card-title>
              <v-card-text>
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.orderApprovalWorkflow')"
                  v-slot="{ errors }"
                >
                  <cz-autocomplete
                    :value="value.accountManagementWorkflowId"
                    :items="
                      accountWorkflows.filter(
                        (item) => item.type === 'account-management-workflow'
                      )
                    "
                    :label="$t('customer.orders.orderApprovalWorkflow')"
                    item-text="name"
                    item-value="_id"
                    :placeholder="$t('customer.orders.selectApprovalWorkflow')"
                    @change="onSelectedAccountManagementlWorkflowChange"
                    :error-messages="errors"
                    :readonly="readonly"
                    dense
                    clearable
                  >
                    <!-- <template #selection="{ item }">
                      <div
                        class="d-flex align-center justify-space-between"
                        style="gap: 1.5rem"
                      >
                        <div class="text-body-1 mb-1">{{ item.name }}</div>
                        <cz-chip
                          color="buttonSecondary"
                          circle-edges
                          x-small
                          :text="
                            $t('customer.workflowManagementPage.stepsCount', [
                              item.steps.length
                            ])
                          "
                          style="max-width: auto"
                        />
                      </div>
                    </template> -->
                    <template #item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between py-4 full-width"
                        style="column-gap: 20px"
                      >
                        <div class="text-body-1 mb-1">{{ item.name }}</div>
                        <cz-chip
                          color="buttonSecondary"
                          circle-edges
                          x-small
                          :text="
                            $t('customer.workflowManagementPage.stepsCount', [
                              item.steps.length
                            ])
                          "
                          style="max-width: auto"
                        />
                      </div>
                    </template>
                  </cz-autocomplete>
                </validation-provider>
                <v-stepper
                  outlined
                  flat
                  width="100%"
                  v-if="value.accountManagementWorkflowSteps.length"
                >
                  <v-stepper-header class="flex-nowrap">
                    <template
                      v-for="(
                        step, index
                      ) in value.accountManagementWorkflowSteps"
                    >
                      <v-stepper-step
                        :key="step.accountWorkflowStepId"
                        :icon="mdiCheck"
                        :step="step.stepNumber"
                        :complete-icon="
                          step.approverUserId ? mdiCheck : mdiCancel
                        "
                        complete
                        :color="!step.approverUserId ? 'error' : 'success'"
                      >
                        {{ step.stepTitle }}
                      </v-stepper-step>
                      <v-divider
                        v-if="
                          index <
                          value.accountManagementWorkflowSteps.length - 1
                        "
                        :key="`${step.accountWorkflowStepId}_divider`"
                      />
                    </template>
                  </v-stepper-header>
                </v-stepper>
                <v-row class="overflow-x-hidden mt-4">
                  <v-col
                    :lg="6"
                    :md="12"
                    :sm="12"
                    v-for="step in value.accountManagementWorkflowSteps"
                    :key="step._id"
                  >
                    <cz-form-field
                      :label="step.stepTitle"
                      :required="!readonly"
                    >
                      <validation-provider
                        rules="required"
                        :name="step.stepTitle"
                        v-slot="{ errors }"
                      >
                        <cz-autocomplete
                          :value="step.approverUserId"
                          :error-messages="errors"
                          :items="getApproverForWorkflowStep(step)"
                          item-text="fullName"
                          item-value="_id"
                          dense
                          :placeholder="
                            $t('customer.orders.selectAccountManager')
                          "
                          :readonly="readonly"
                          @change="
                            onAccountManagementApproverUserChanged($event, step)
                          "
                          clearable
                        >
                          <template #selection="{ item }">
                            <div
                              class="d-flex align-center"
                              style="column-gap: 20px"
                            >
                              <cz-avatar
                                :size="25"
                                color="buttonSecondary"
                                :name="item.fullName"
                                initials-size="font-weight-semibold text-subtitle-2 text-uppercase"
                              />
                              <span class="text-body-1">
                                {{ item.fullName }}</span
                              >
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <cz-icon
                                    :src="mdiFileSign"
                                    v-if="item.isDigitalArchiveSigner"
                                    v-on="on"
                                    v-bind="attrs"
                                  />
                                </template>
                                <span class="text-pre-wrap">{{
                                  $t(
                                    'customer.digitalArchive.isDigitalArchiveSignerHint'
                                  )
                                }}</span>
                              </v-tooltip>
                            </div>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-avatar>
                              <cz-avatar
                                :size="25"
                                color="buttonSecondary"
                                :name="item.fullName"
                                initials-size="font-weight-semibold text-subtitle-2 text-uppercase"
                              />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fullName }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="item.isDigitalArchiveSigner"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <div
                                    class="d-flex align-center"
                                    style="column-gap: 10px"
                                    v-on="on"
                                    v-bind="attrs"
                                  >
                                    <cz-icon :src="mdiFileSign" />

                                    <span class="text-subtitle-2">{{
                                      $t(
                                        'customer.digitalArchive.isDigitalArchiveSigner'
                                      )
                                    }}</span>
                                  </div>
                                </template>
                                <span class="text-pre-wrap">{{
                                  $t(
                                    'customer.digitalArchive.isDigitalArchiveSignerHint'
                                  )
                                }}</span>
                              </v-tooltip>
                            </v-list-item-action>
                          </template>
                        </cz-autocomplete>
                      </validation-provider>
                    </cz-form-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import {
  CzFormField,
  CzAutocomplete,
  CzAvatar,
  CzChip,
  CzIcon
} from '@/components';
import { userHasOneOfRolesWithName } from '@/shared/services/rbac/rbac.service';
import { mdiCheck, mdiCancel, mdiFileSign } from '@mdi/js';
import RoleType from '@/shared/types/RoleType';
export default {
  name: 'OrderApprovals',
  components: {
    CzFormField,
    CzAutocomplete,
    CzAvatar,
    CzChip,
    CzIcon
  },
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    accountWorkflows: {
      type: Array,
      default: () => []
    },
    approvers: {
      type: Object
    },
    currentUser: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isManualCreationMode() {
      return this.value?.creationType === 'manual';
    }
  },
  created() {
    // this.order = cloneDeep(this.value);
  },
  data() {
    return {
      order: null,
      mdiFileSign,
      mdiCheck,
      mdiCancel
    };
  },

  methods: {
    getApproverForWorkflowStep(workflowStep) {
      if (!workflowStep.approverRole || !this.approvers) {
        return [];
      }
      return this.approvers[workflowStep.approverRole]?.map(
        (item) => item.user
      );
    },
    onSelectedOrderApprovalWorkflowChange(value) {
      const _order = cloneDeep(this.value);
      _order.orderApprovalWorkflowId = value;
      _order.orderApprovalWorkflowSteps = [];
      if (!value) {
        return;
      }

      const orderApprovalWorkflow = this.accountWorkflows.find(
        (item) => item._id === value
      );
      for (const step of orderApprovalWorkflow.steps) {
        _order.orderApprovalWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: _order._id,
          stepNumber: step.stepNumber,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }

      this.$emit('input', _order);
    },
    onSelectedOrderSubmissionlWorkflowChange(value) {
      const _order = cloneDeep(this.value);
      _order.orderSubmissionWorkflowId = value;

      _order.orderSubmissionWorkflowSteps = [];
      if (!value) {
        return;
      }

      const orderSubmissionWorkflow = this.accountWorkflows.find(
        (item) => item._id === value
      );
      for (const step of orderSubmissionWorkflow.steps) {
        _order.orderSubmissionWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: _order._id,
          stepNumber: step.stepNumber,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }

      const currentUserIsPurchaser = userHasOneOfRolesWithName(
        this.currentUser,
        RoleType.CUSTOMER_PURCHASER
      );

      if (currentUserIsPurchaser) {
        const purchaserStep = _order.orderSubmissionWorkflowSteps.find(
          (item) => item.approverRole === RoleType.CUSTOMER_PURCHASER
        );

        if (purchaserStep) {
          purchaserStep.approverUserId = this.currentUser._id;
        }
      }

      this.$emit('input', _order);
    },
    onSelectedAccountManagementlWorkflowChange(value) {
      const _order = cloneDeep(this.value);
      _order.accountManagementWorkflowId = value;

      _order.accountManagementWorkflowSteps = [];
      if (!value) {
        return;
      }

      const workflow = this.accountWorkflows.find((item) => item._id === value);
      for (const step of workflow.steps) {
        _order.accountManagementWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: _order._id,
          stepNumber: step.stepNumber,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }

      this.$emit('input', _order);
    },
    onOrderApproverUserChanged(value, step) {
      const _order = structuredClone(this.value);
      const index = this.value.orderApprovalWorkflowSteps.findIndex(
        (item) => item._id === step._id
      );
      _order.orderApprovalWorkflowSteps[index].approverUserId = value;
      this.$emit('input', _order);
    },
    onOrderSubmissionApproverUserChanged(value, step) {
      const _order = structuredClone(this.value);
      const index = this.value.orderSubmissionWorkflowSteps.findIndex(
        (item) => item._id === step._id
      );
      _order.orderSubmissionWorkflowSteps[index].approverUserId = value;
      this.$emit('input', _order);
    },
    onAccountManagementApproverUserChanged(value, step) {
      const _order = structuredClone(this.value);
      const index = this.value.accountManagementWorkflowSteps.findIndex(
        (item) => item._id === step._id
      );
      _order.accountManagementWorkflowSteps[index].approverUserId = value;
      this.$emit('input', _order);
    },
    onAccountManagerUserChanged(value) {
      const _order = structuredClone(this.value);
      _order.orderSubmissionAccountManagerUserId = value;
      this.$emit('input', _order);
    }
  }
};
</script>

<style lang="scss" scoped></style>
